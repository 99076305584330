import React, { useEffect } from 'react'
import { animated, config as springConfig, useSpring } from 'react-spring'
//import { redirectTo } from '@reach/router'

import { makeStyles } from '@material-ui/core/styles'
//import { Paper } from '@material-ui/core'

import config from '../config'

const APP_URL = config.APP_URL

const useStyles = makeStyles(
	theme => ({
		root: {
			alignItems: 'center',
			backgroundColor: '#f6f7fc',
			bottom: 0,
			display: 'flex',
			justifyContent: 'center',
			left: 0,
			position: 'fixed',
			right: 0,
			top: 0,
		},
		logo: {
			height: 128,
			marginBottom: theme.spacing(4),
		},
		paper: {
			alignItems: 'center',
			backgroundColor: '#fff',
			borderRadius: 21,
			boxShadow:
				'rgba(21, 23, 26, 0.06) 0px 0.7rem 1.3rem 0px, rgba(21, 23, 26, 0.12) 0px 1rem 2.2rem 0px, rgba(21, 23,26, 0.04) 0px -1px 0px 0px',
			display: 'flex',
			flexDirection: 'column',
			height: 640,
			justifyContent: 'center',
			padding: theme.spacing(5),
			width: 640,

			'& blockquote': {
				minHeight: 134,
			},
		},
	}),
	{ name: 'LoginPage' }
)

const LoginPage = () => {
	const springStyle = useSpring({
		config: springConfig.slow,
		from: { opacity: 0 },
		to: { opacity: 1 },
	})
	const classes = useStyles()

	useEffect(() => {
		setTimeout(() => {
			window.location.replace(`${APP_URL}/login`)
		}, 200)
	}, [])

	return (
		<animated.div style={springStyle}>
			<div className={classes.root}>
				{/* <Paper className={classes.paper}>
					<img
						className={classes.logo}
						src={`${APP_URL}/tyto-logo-128.png`}
						alt={'Tyto logo'}
					/>
					<blockquote />
				</Paper> */}
			</div>
		</animated.div>
	)
}

export default LoginPage
